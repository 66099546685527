import React from "react";
import Layout from "../components/Layout";
import { Footer } from "../components/contact/contact";
import SEO from "../components/DynamicTitle";

interface Props {}

const Contact = (props: Props) => {
  return (
    <Layout>
      <SEO title="Contact" subTitle="" />
      <Footer />
    </Layout>
  );
};

export default Contact;
